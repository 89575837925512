var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_vm._l(_vm.getItems, function (item) {
    return _c('SupportItem', {
      key: item.id,
      attrs: {
        "item": item
      },
      on: {
        "on-create": _vm.createItem,
        "on-update": _vm.updateItem,
        "on-delete": _vm.onDelete
      }
    });
  }), _c('confirmation-modal', {
    attrs: {
      "id": "delete-modal",
      "variant": "danger",
      "yes-key": "support.modal.delete"
    },
    on: {
      "on-click-yes": function onClickYes($event) {
        return _vm.onDeleteConfirmed();
      }
    },
    scopedSlots: _vm._u([{
      key: "body-text",
      fn: function fn() {
        var _vm$deleteItem;

        return [_c('h2', {
          staticClass: "modal-title"
        }, [_vm._v(" " + _vm._s(_vm.$t('support.modal.title')) + " ")]), _c('p', {
          staticClass: "modal-p"
        }, [_vm._v(" " + _vm._s(_vm.$t('support.modal.body')) + " "), _c('strong', [_vm._v(" " + _vm._s("".concat((_vm$deleteItem = _vm.deleteItem) === null || _vm$deleteItem === void 0 ? void 0 : _vm$deleteItem.title)) + " ")])])];
      },
      proxy: true
    }])
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }