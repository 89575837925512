var render = function () {
  var _vm$item, _vm$item2, _vm$item3, _vm$item4, _vm$item5, _vm$item7, _vm$item8;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 wrapper mt-2"
  }, [_c('div', {
    staticClass: "section-wrapper d-flex justify-content-between p-3 w-100"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.title) + " ")]), !_vm.isOpen ? _c('div', {
    staticClass: "action",
    on: {
      "click": _vm.onEditClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("support.section.".concat((_vm$item2 = _vm.item) !== null && _vm$item2 !== void 0 && _vm$item2.id ? 'edit' : 'new'))) + " ")]) : _vm._e()]), _vm.isOpen ? _c('div', {
    staticClass: "w-100 p-3 content-wrapper"
  }, [_c('b-form-input', {
    staticClass: "title-input",
    attrs: {
      "id": "input-".concat((_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.id),
      "type": "text",
      "placeholder": _vm.$t('support.form.title'),
      "state": _vm.getStateWithoutSubmit('title')
    },
    model: {
      value: _vm.$v.formData.title.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.formData.title, "$model", $$v);
      },
      expression: "$v.formData.title.$model"
    }
  }), !_vm.$v.formData.title.required ? _c('b-form-invalid-feedback', {
    attrs: {
      "id": "input-".concat((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.id, "-feedback")
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('validation.required', {
    field: _vm.$t('support.form.title')
  })) + " ")]) : _vm._e(), !_vm.$v.formData.title.maxLength ? _c('b-form-invalid-feedback', {
    attrs: {
      "id": "input-".concat((_vm$item5 = _vm.item) === null || _vm$item5 === void 0 ? void 0 : _vm$item5.id, "-feedback")
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('validation.max-length', {
    field: _vm.$t('support.form.title'),
    max: _vm.$v.formData.title.$params.maxLength.max
  })) + " ")]) : _vm._e(), _c('div', {
    staticClass: "mt-3"
  }, [_c('editor', {
    attrs: {
      "api-key": _vm.editorKey,
      "init": {
        height: 300,
        menu: {
          file: {
            title: 'File',
            items: 'newdocument restoredraft | preview | print '
          },
          edit: {
            title: 'Edit',
            items: 'undo redo | cut copy paste | selectall | searchreplace'
          },
          view: {
            title: 'View',
            items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen'
          },
          insert: {
            title: 'Insert',
            items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime'
          },
          format: {
            title: 'Format',
            items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat'
          },
          tools: {
            title: 'Tools',
            items: 'spellchecker spellcheckerlanguage | code wordcount'
          },
          table: {
            title: 'Table',
            items: 'inserttable | cell row column | tableprops deletetable'
          }
        },
        plugins: ['advlist autolink lists link image charmap print preview anchor', 'searchreplace visualblocks code fullscreen', 'insertdatetime media table paste code wordcount'],
        toolbar: 'undo redo | formatselect fontsizeselect fontselect | bold italic forecolor backcolor | \
       alignleft aligncenter alignright alignjustify | \
       bullist numlist outdent indent | removeformat'
      }
    },
    model: {
      value: _vm.formData.content,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "content", $$v);
      },
      expression: "formData.content"
    }
  })], 1), _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        var _vm$item6;

        (_vm$item6 = _vm.item) !== null && _vm$item6 !== void 0 && _vm$item6.id ? _vm.update() : _vm.create();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("support.button.".concat((_vm$item7 = _vm.item) !== null && _vm$item7 !== void 0 && _vm$item7.id ? 'update' : 'create'))) + " ")]), (_vm$item8 = _vm.item) !== null && _vm$item8 !== void 0 && _vm$item8.id ? _c('b-button', {
    staticClass: "mt-2 ml-3",
    attrs: {
      "type": "submit",
      "variant": "danger"
    },
    on: {
      "click": _vm.onDelete
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("support.button.delete")) + " ")]) : _vm._e(), _c('b-button', {
    staticClass: "mt-2 ml-3",
    attrs: {
      "type": "submit",
      "variant": "secondary"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("support.button.cancel")) + " ")])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }