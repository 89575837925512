






















































































































import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import Editor from '@tinymce/tinymce-vue';
import { PageContent } from '@/model/PageContent';
import { PropType } from 'vue';
import { Validations } from 'vuelidate-property-decorators';
import { maxLength, required } from 'vuelidate/lib/validators';
import { mixins } from 'vue-class-component';
import FormValidationMixin from '@/mixins/FormValidationMixin';

@Component({
    components: {
        Editor,
    },
})
export default class SupportItem extends mixins(FormValidationMixin) {
    @Prop({ type: Object as PropType<PageContent> })
    item!: PageContent;
    editorKey = process.env.VUE_APP_EDITOR_KEY;

    formData = {
        title: this.item?.title ?? '',
        content: this.item?.content ?? '',
    };

    isOpen = false;

    @Watch('item')
    itemUpdate(): void {
        this.formData.content = this.item?.content ?? '';
        this.formData.title = this.item?.title ?? '';
    }

    @Validations()
    validations = {
        formData: {
            title: { required, maxLength: maxLength(100) },
        },
    };

    @Emit()
    onUpdate(): PageContent {
        return { ...this.item, ...this.formData };
    }

    cancel(): void {
        this.formData.content = this.item?.content ?? '';
        this.formData.title = this.item?.title ?? '';
        this.$v.formData.$reset();
        this.onEditClick();
    }

    update(): void {
        this.$v.$touch();
        if (!this.$v.$invalid) {
            this.onUpdate();
        }
    }

    @Emit()
    onCreate(): PageContent {
        return { ...this.item, ...this.formData };
    }

    create(): void {
        this.$v.$touch();
        if (!this.$v.$invalid) {
            this.onCreate();
        }
    }

    @Emit()
    onDelete(): PageContent {
        return this.item;
    }

    onEditClick(): void {
        this.isOpen = !this.isOpen;
    }
}
