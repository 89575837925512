































import SupportItem from '@/components/support/Item.vue';
import ToastMixin from '@/mixins/ToastMixin';
import { PageContent } from '@/model/PageContent';
import ToastVariant from '@/model/toast/toast-variant';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { mapActions, mapGetters } from 'vuex';
import ConfirmationModal from '@/components/modal/ConfirmationModal.vue';

@Component({
    components: {
        SupportItem,
        ConfirmationModal,
    },
    methods: {
        ...mapActions({
            fetch: 'pageContentStorage/fetchSupports',
            create: 'pageContentStorage/createSupport',
            update: 'pageContentStorage/updateSupport',
            delete: 'pageContentStorage/deleteSupport',
        }),
    },
    computed: {
        ...mapGetters({
            getItems: 'pageContentStorage/getSupports',
        }),
    },
})
export default class SupportAndHelp extends mixins(ToastMixin) {
    fetch!: () => Promise<void>;
    create!: (item: PageContent) => Promise<void>;
    update!: (item: PageContent) => Promise<void>;
    delete!: (item: PageContent) => Promise<void>;

    getItems!: PageContent[] | null;

    deleteItem: PageContent | null = null;

    mounted(): void {
        this.fetch();
    }

    createItem(item: PageContent): void {
        this.create(item)
            .then(() => {
                this.fetch();
                this.showToast(
                    this.$t('support.create-success').toString(),
                    this.$t('success-title').toString(),
                    ToastVariant.SUCCESS,
                );
            })
            .catch(() => {
                this.showToast(
                    this.$t('support.create-fail').toString(),
                    this.$t('error-title').toString(),
                    ToastVariant.DANGER,
                );
            });
    }

    updateItem(item: PageContent): void {
        this.update(item)
            .then(() => {
                this.fetch();
                this.showToast(
                    this.$t('support.update-success').toString(),
                    this.$t('success-title').toString(),
                    ToastVariant.SUCCESS,
                );
            })
            .catch(() => {
                this.showToast(
                    this.$t('support.update-fail').toString(),
                    this.$t('error-title').toString(),
                    ToastVariant.DANGER,
                );
            });
    }

    onDelete(item: PageContent): void {
        this.$bvModal.show(`delete-modal`);
        this.deleteItem = item;
    }

    onDeleteConfirmed(): void {
        if (this.deleteItem) {
            this.delete(this.deleteItem).then(() => {
                this.deleteItem = null;
                this.$bvModal.hide(`delete-modal`);
                this.showToast(
                    this.$t('support.delete-success').toString(),
                    this.$t('success-title').toString(),
                    ToastVariant.SUCCESS,
                );
                this.fetch();
            });
        }
    }
}
